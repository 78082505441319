import DocumentHeader from '@/components/DocumentHeader';
import SkyscraperBackground from '@/components/SkyscraperBackground';

import { useTracking } from 'react-tracking';
import { Button, Box, Heading, Text, Textfield } from '@simplywallst/ui-core';
import CheckSolid from '@simplywallst/ui-core/icons/CheckSolid';
import type { FormEvent } from 'react';
import { useState } from 'react';
import { useDebounce } from '@simplywallst/hooks/useDebounce';
import { useMutation } from '@tanstack/react-query';
import { newPassword } from '@simplywallst/services';
import axios from 'axios';
import { useLocation } from '@/hooks/useLocation';
import Notification from '@/components/Notification';
import { useDispatch } from 'react-redux';
import { redirect } from 'redux-first-router';
import { ROUTE_WELCOME } from '@/constants/routes';
import { useHandleLoginResponse } from '@/hooks/useLogin/useHandleLoginResponse';
import { useSnackbar } from '@/features/snackbar';

function validatePassword(password: string) {
  const result = {
    length: password.length >= 10,
    lowercase: /[a-z]/.test(password),
    uppercase: /[A-Z]/.test(password),
    number: /\d/.test(password),
    symbol: /\W/.test(password),
  };

  const { length, ...rest } = result;
  const lenghtPassed = length;
  const passedChecks = Object.values(rest).filter(Boolean).length;

  return {
    valid: lenghtPassed && passedChecks >= 3,
    ...result,
  };
}

export function SocialToLocal() {
  const { Track } = useTracking({
    type: 'track',
    page: 'social-to-local',
    subject: 'page',
  });

  const { handleLoginResponse } = useHandleLoginResponse();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const debouncedPassword = useDebounce(password, 500);

  const [confirmPassword, setConfirmPassword] = useState('');
  const debouncedConfirmPassword = useDebounce(confirmPassword, 500);
  const { addSnackbar } = useSnackbar();

  const { mutate, status, error } = useMutation({
    async mutationFn({
      password,
      confirm,
      token,
    }: {
      password: string;
      confirm: string;
      token: string | null;
    }) {
      if (typeof token !== 'string') {
        throw new Error('Invalid token');
      }
      const response = await newPassword({
        password: password,
        password_confirm: confirm,
        token,
      });
      return response;
    },
    onSuccess(response) {
      handleLoginResponse({ ...response, provider: 'sws' });
      addSnackbar({
        type: 'positive',
        message: 'Sucessfully converted to email login.',
        lifeTime: 5000,
        actionLabel: 'OK',
      });
    },
  });

  const handlePost = (e: FormEvent) => {
    e.preventDefault();
    mutate({
      password: debouncedPassword,
      confirm: debouncedConfirmPassword,
      token,
    });
  };
  const handlePasswordUpdate = () => {
    mutate({
      password: debouncedPassword,
      confirm: debouncedConfirmPassword,
      token,
    });
  };

  const passwordValidation = validatePassword(debouncedPassword);
  const confirmPasswordValidation = validatePassword(confirmPassword);

  function getPasswordErrorMessage() {
    if (debouncedPassword === '') {
      return '';
    }
    if (!passwordValidation.valid) {
      return 'Minimum of 10 characters and must contain 3 out of 4 characters from the following; lowercase, uppercase, symbol and numeric.';
    }
    return '';
  }

  function getConfirmPasswordErrorMessage() {
    if (debouncedConfirmPassword === '') {
      return '';
    }

    if (debouncedPassword !== debouncedConfirmPassword) {
      return 'Passwords do not match';
    }

    return '';
  }
  const passwordErrorMessage = getPasswordErrorMessage();
  const confirmPasswordErrorMessage = getConfirmPasswordErrorMessage();

  const isValid =
    debouncedPassword === debouncedConfirmPassword &&
    passwordValidation.valid &&
    confirmPasswordValidation.valid;

  let APIErrorMessage = 'Something went wrong. Please try again.';
  if (error && axios.isAxiosError(error)) {
    APIErrorMessage =
      typeof error.response?.statusText === 'string' &&
      error.response.statusText !== ''
        ? error.response?.statusText
        : APIErrorMessage;
    if (error.response?.statusText === '') {
      // attempt to infer the error via status code
      if (error.response.status === 401) {
        APIErrorMessage = 'Invalid token. Please retry account recovery.';
      }
    }
  }
  return (
    <Track>
      <SkyscraperBackground>
        <DocumentHeader title={'Change to email login'} allowCrawlers={false} />
        {error ? (
          <Notification alert>
            <Text typography="xSmall">{APIErrorMessage}</Text>
          </Notification>
        ) : (
          <></>
        )}
        {status === 'success' && (
          <Notification success>
            <Text typography="xSmall">
              Password set successful. You can now{' '}
              <Button
                styleType="softLink"
                onClick={() => {
                  dispatch(redirect({ type: ROUTE_WELCOME }));
                }}
              >
                login
              </Button>
              .
            </Text>
          </Notification>
        )}
        <Box marginBottom={'x2spacePx'}>
          <Heading as="h1" color="almostBlack">
            Confirm change to email login
          </Heading>
        </Box>
        <form method="POST" onSubmit={handlePost}>
          <Box marginBottom={passwordErrorMessage ? 'x8spacePx' : 'x2spacePx'}>
            <Textfield
              styleType="standardReverse"
              type="password"
              autoComplete="new-password"
              name="password"
              label="Set New Password"
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
              disabled={status === 'pending'}
              error={passwordErrorMessage}
              postfix={passwordValidation.valid && <CheckSolid />}
            />
          </Box>
          <Box marginBottom="x4spacePx">
            <Textfield
              styleType="standardReverse"
              type="password"
              autoComplete="new-password"
              name="confirmPassword"
              label="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.currentTarget.value)}
              disabled={status === 'pending'}
              error={confirmPasswordErrorMessage}
              postfix={confirmPasswordValidation.valid && <CheckSolid />}
            />
          </Box>
          <Box>
            <Button
              styleType="louderLarge"
              disabled={!isValid || status === 'pending'}
              isLoading={status === 'pending'}
              marginRight
              type="submit"
              onClick={handlePasswordUpdate}
              data-cy-id="update-password"
            >
              Confirm and set password
            </Button>
          </Box>
        </form>
      </SkyscraperBackground>
    </Track>
  );
}
